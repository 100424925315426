
import * as React from "react";
import { Api } from "../lib/Api";
import { TStorage } from "../../avernus/tstorage/TStorage";

// import logoImg from '../../www/assets/img/logo.png';

interface ILoginProps {
    setUrl:any
}
interface IForm{
    userName:string
    password:string
}
interface ILoginState {
    error: string
    form : IForm
    loading      : boolean
}

interface ILoginResult{
    error       : string;
    api_token   : string;
    permission  : string;
    name        : string;
    tokenError  : boolean;
}


export default class Login extends React.Component<ILoginProps, ILoginState>{

  	constructor(props:ILoginProps){
        super(props);
        this.state = {
            error: null,
            form: {
                userName: '',
                password: ''
            },
            loading: false
        };
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var form = this.state.form;
        form[type] = evt.target.value;
        this.setState({
             form: form
        });

    }


    public login(e):void{
        e.preventDefault();
        this.setState({
             loading: true
        }, () =>{
            Api.call('/login', {
                userName: this.state.form.userName,
                password: this.state.form.password
            }, (result:ILoginResult) =>{
                if ( result.api_token !== undefined && result.api_token !== null ) {
                    Api.setPermission(result.permission);
                    Api.setToken(result.api_token);
                    new TStorage().set('name', result.name);
                    this.setState({
                        error: null,
                    }, () =>{
                        this.props.setUrl(null); //last url
                    });

                }else{
                    var error = result.error;
                    if ( result.tokenError !== undefined ) {
                        error = result.tokenError?'Hibás azonosító':'';
                    }
                    this.setState({
                        error: result.error,
                        loading: false
                    });
                }
            });
        });
    }

    render() {
        return (
            <>

                <div className="login-dark">
                    <form method="post" style={{backgroundColor: 'rgb(255,255,255)'}} onSubmit={ e => this.login(e) }
                    >
                        <div className="illustration"><img src="assets/img/logo.png" /></div>
                        <div className="form-group">
                            <input type="text" className="form-control" value={ this.state.form.userName  } onChange={ (e) => this.changeInputHandler(e, 'userName') } placeholder="felhasználónév" required={true} />
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" value={ this.state.form.password  } onChange={ (e) => this.changeInputHandler(e, 'password') } placeholder="jelszó" required={true} />
                        </div>
                        { this.state.error !== null?<div className="alert alert-danger">
                            { this.state.error }
                        </div>:'' }
                        <div className="form-group" style={{marginTop: '20px'}}>

                            { this.state.loading === true?<button className="btn btn-secondary btn-block" disabled={true} type="submit">Folyamatban...</button>:<button className="btn btn-secondary btn-block" type="submit">Bejelentkezés</button> }

                        </div>
                    </form>
                </div>
			</>
        );
    }
}
